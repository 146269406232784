import { Button, Col, Row } from "react-bootstrap"
import React, { useContext, useEffect, useState } from 'react'
import "./contact.css"
import { MobileContext } from "../store/ContextPorovider"

const ContactNote = () => {
    return (
        <div className="logo">

            We are delighted to fullfill your photography needs.  <br></br>
            To help us to get better understanding of your photo shoot,<br></br>
            please contact us with any questions or queries you might have including some of the following details:
            <ul>
                <li>Suburb / State where photoshoot is required</li>
                <li>Estimated Date</li>
                <li>Type of Photography desired i.e. portraits, events, wedding, family, pets, food or landscapes.</li>
            </ul>
        </div>
    )
}
export const Contact = () => {



    const { isMobile, setMobile } = useContext(MobileContext)


    return (
        <div>
            <div className="outer-box" >
                <img src="/images/images3.jpg" alt="dfd" />
                <div className="contact-details" >
                    {!isMobile && <ContactNote />}
                    <img src="/logo.png" style={{ marginLeft: 5, height: "100px", width: "auto" }} alt="ak photography" className="logo" />
                    <Row>
                        <Col xs={12}> <h6>akphotographyau@gmail.com</h6></Col>
                        <Col xs={12}><a href="mailto:akphotographyau@gmail.com">
                            <Button>Email us</Button> </a></Col>
                        <Col xs={12}></Col>
                    </Row>


                </div>

            </div>
            {isMobile === true && <ContactNote />}
        </div>

    )
}