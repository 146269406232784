
import { Card, Button, Container } from "react-bootstrap"
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom"
import { ServiceImage } from "../components/ServiceImage"
import "../components/ServiceImage.css"
import { Fade } from "react-reveal"
import { MobileContext } from "../store/ContextPorovider";



const ServiceDescription = (props) => {
    return (<div className="service-description">{props.description}</div>)
}
const ServiceContainer = (props) => {
    const image1 = props.firstImage;
    const image2 = props.secondImage;
    const description = props.description
    const filter = props.filter

    return (
        <div className="service-container">
            <Fade>
                <ServiceImage firstImage={image1} secondImage={image2} description={description} filter={filter} />
                {/* <ServiceDescription description={description} /> */}
            </Fade>
        </div>
    )
}
const PService = (props) => {
    const img = props.imageSrc
    const title = props.title
    const isMobile = props.isMobile
    const description = props.description
    const link = props.link

    const styles = {
        // root:{
        //     display: 'flex',
        //     flexWrap: 'wrap',
        //     justifyContent: 'space-around',
        //     overflow: 'hidden',

        // },
        card: {
            backgroundColor: '#B7E0F2',
            borderRadius: 30,
            width: '100%',
            // padding: '3rem',
            // margin: '1rem',
            //flexWrap: 'nowrap'
        },
        cardImage: {
            objectFit: 'cover',
            borderRadius: 30,
            width: '100%',
            height: '30vh'
        }
        //
    }
    return (
        <div style={{ width: isMobile ? '100%' : '50rem', padding: isMobile ? "0px":'1rem', }}>

            <Card style={styles.card}
                className="bg-dark text-white">
                <Card.Img variant="left" src={img} style={styles.cardImage} />
                <Card.Body style={{textAlign:"center"}}>
                    
                        <Card.Text>{description}</Card.Text>
                    
                    <Link to={link}><Button variant="primary" size="sm"
                    style={{ width: "90%", margin: "auto" }}>{title}</Button></Link>
                </Card.Body>
                {/* <Card.ImgOverlay style={{backgroundColor: "rgba(255,255,255,0.2)", height:"50%",
            width:"100%", position:"relative", border:"1px 1px solid gray", borderRadius:"20px", alignSelf:"end"}}>
             
              
            </Card.ImgOverlay> */}
            </Card>
            <br />
        </div>
    )
}
export const PhotoServices = (props) => {
   
    const {isMobile, setMobile} =  useContext(MobileContext)

    // const isMobile = props.isMobile
    const serviceList = []
    serviceList.push({
        img: "/images/portfolio/portraits/portrait1.jpg",
        title: "Portraits",
        description: "close up, headshots, weddings, engagements",
        link: "/portfolio"

    });
    serviceList.push({
        img: "/images/images1.jpg",
        title: "Family",
        description: "Family events, birthdays, babies and because you feel like it",
        link: "/portfolio"
    });
    serviceList.push({
        img: "/images/images2.jpg",
        title: "Pets",
        description: "Fun photo session with your beloved fur babies",
        link: "/portfolio"
    });
    serviceList.push({
        img: "/images/images5.jpg",
        title: "Food",
        description: "Photos for your restaurant or cafes menu items",
        link: "/portfolio"
    });
    return (<div style={{ display: isMobile ? "block" : "flex", width: "100%", overflow:"auto",  margin: "auto" }}>
       
            {serviceList.map((service) => {

                return <PService imageSrc={service.img}
                    title={service.title}
                    isMobile={isMobile}
                    link={service.link}
                    description={service.description}
                    key={service.title} />

            })}
       


    </div>
    )
}

export const Packages = () => {

    const bwfilter = "grayscale(100%)"
    const focusFilter = "blur(5px)"
    const sepiaFilter = "sepia(35%) saturate(100%) contrast(90%)"
    const brightnessFilter = "brightness(50%)"
    const services = [
        {
            img: "/images/images1.jpg", img2: "/images/images2.jpg",
            description: "Remove distractions of colour and focus on the aspects of photos including textures, shapes, patterns and composition", filter: bwfilter
        },
        { img: "/images/images2.jpg", img2: "/images/images1.jpg", description: "Say bye to blurry photos and let your family and friends focus on you and your moments", filter: focusFilter },
        { img: "/images/images1.jpg", img2: "/images/images3.jpg", description: "Live your moments in past with vintage looks", filter: sepiaFilter },
        { img: "/images/images1.jpg", img2: "/images/images4.jpg", description: "Different weathers, locations and timings affects the brightness and exposures, we've got you covered with perfectly exposed photos", filter: brightnessFilter },

    ]





    return (<div>

        <PhotoServices />
        <div style={{ width: "80%", margin: "auto", padding: 20 }}>
            <h3>AK photography includes complimentary colour correction and customisation</h3>
            <p>move slider to see the difference</p>
        </div>

        {services.map((service) => {
            return <ServiceContainer key={service.description} firstImage={service.img} secondImage={service.img2} filter={service.filter} description={service.description} />
        })}

    </div>
    )
}