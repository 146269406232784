
import React, { createContext, useState} from "react";
import { Outlet } from "react-router-dom";
export const MobileContext = createContext(null);

export const ContextProvider = ()=>{
  const [isMobile, setMobile] = useState(false);
  return (
    <MobileContext.Provider value={{ isMobile: isMobile, setMobile: setMobile }}>
      <Outlet/>

    </MobileContext.Provider>
  )
}