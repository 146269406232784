import { Button, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./Footer.css"
import { Fade } from "react-reveal"
import SocialButton from "../components/SocialButton"


export const Footer = () => {

    return (

        <Fade>





            <div style={{ zIndex: 10,
                 backgroundColor: "black",
                  width: "100%",
                   color: "white",
                    bottom: 0,
                     position: "fixed",
                     
                      }}>

                <div className="footer" >
                    <div style={{ display: "flex", width: "50%", margin: "auto", }}>
    
                        <Row xs={12} >
                            <Col xs={6} md={3} style={{marginTop:"25px"}}>
                                <Link to="/packages">Services </Link>
                            </Col>
                            <Col xs={6} md={3} style={{marginTop:"25px"}}>
                                <Link to="/contact">Contact</Link>
                            </Col>

                            <Col xs={6} md={3}>
                                <SocialButton image="/images/social_media/instagram.svg"
                                 title="follow us" link="https://instagram.com/ak_photography_au" />
                            </Col>
                            <Col xs={6} md={3}>
                                <SocialButton image="/images/social_media/facebook.png" 
                                title="Like us"  link="https://www.facebook.com/photographywithkunwar" />
                            </Col>
                            <Col xs={12} md={6}>ABN: 68714360487</Col>
                            <Col xs={12} md={6}>AK Photography Copyrights 2023</Col>
                        </Row>





                    </div>

                </div>



            </div>
        </Fade>)
}