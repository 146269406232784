import { useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { Grid } from "../components/GalleryGrid"


export const Portfolio = () => {

    const [tabKey, setTabKey] = useState("portraits");
    const landscapes = [{ description: "first slide", img: "/images/portfolio/landscapes/landscapes1.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes2.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes3.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes4.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes5.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes6.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes7.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes8.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes9.jpg" },
    { description: "first slide", img: "/images/portfolio/landscapes/landscapes10.jpg" },
]
    const portraits = [
        { description: "Kate getting ready", img: "/images/portfolio/portraits/portrait1.jpg" },
        { description: "Mick and Rob", img: "/images/portfolio/portraits/portrait2.jpg" },

        { description: "Ryan", img: "/images/portfolio/portraits/portrait3.jpg" },
      
        { description: "Ryan", img: "/images/portfolio/portraits/portrait5.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait6.jpg" },
     
        { description: "Ryan", img: "/images/portfolio/portraits/portrait8.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait9.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait10.jpg" },
   
        { description: "Ryan", img: "/images/portfolio/portraits/portrait12.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait13.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait14.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait15.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait16.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait17.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait18.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait19.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait20.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait21.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait22.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait23.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait24.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait4.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait7.jpg" },
        { description: "Ryan", img: "/images/portfolio/portraits/portrait11.jpg" },
    ]
    const food = [
        { description: " ", img: "/images/portfolio/food/food3.jpg" },
        { description: " ", img: "/images/portfolio/food/food4.jpg" },
        { description: " ", img: "/images/portfolio/food/food5.jpg" },
        { description: " ", img: "/images/portfolio/food/food6.jpg" },
        { description: " ", img: "/images/portfolio/food/food7.jpg" },
        { description: " ", img: "/images/portfolio/food/food8.jpg" },
        { description: " ", img: "/images/portfolio/food/food9.jpg" },
        { description: " ", img: "/images/portfolio/food/food10.jpg" },
        { description: " ", img: "/images/portfolio/food/food11.jpg" },
        { description: " ", img: "/images/portfolio/food/food12.jpg" },
        { description: " ", img: "/images/portfolio/food/food13.jpg" },
        { description: " ", img: "/images/portfolio/food/food14.jpg" },
        { description: " ", img: "/images/portfolio/food/food15.jpg" },
        { description: " ", img: "/images/portfolio/food/food16.jpg" },
    ]
    const pets = [
        { description: "", img: "/images/portfolio/pets/pet1.jpg" },
        { description: "", img: "/images/portfolio/pets/pet2.jpg" },
        { description: "", img: "/images/portfolio/pets/pet3.jpg" },
        { description: "", img: "/images/portfolio/pets/pet4.jpg" },
        { description: "", img: "/images/portfolio/pets/pet5.jpg" },
        { description: "", img: "/images/portfolio/pets/pet6.jpg" },
        { description: "", img: "/images/portfolio/pets/pet7.jpg" },
        { description: "", img: "/images/portfolio/pets/pet8.jpg" },
        { description: "", img: "/images/portfolio/pets/pet9.jpg" },
        { description: "", img: "/images/portfolio/pets/pet10.jpg" },
        { description: "", img: "/images/portfolio/pets/pet11.jpg" },
        { description: "", img: "/images/portfolio/pets/pet12.jpg" },
        { description: "", img: "/images/portfolio/pets/pet13.jpg" },
    ]
    return (



        <div>



            <Tabs defaultActiveKey={"portraits"} id="uncontrolled-tabs" className="mb-5"

                activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey={"portraits"} title="Portraits">

                    <Grid images={portraits} title="Portraits" />

                </Tab>

                <Tab eventKey={"landscapes"} title="Landscapes">
                    <Grid images={landscapes} title="Landscapes" />
                </Tab>
                <Tab eventKey={"pets"} title="Pets">
                    <Grid images={pets} title="Pets" />
                </Tab>
                <Tab eventKey={"food"} title="Food">
                    <Grid images={food} title="Food" />
                </Tab>

            </Tabs>
        </div>
    )
}