import { Carousel, Container } from "react-bootstrap";



const CarouselItems = (props) => {
    const items = props.items;



    return (
        <Container>
            <Carousel>
                {items.map((item, i) => {
                    return (

                        <Carousel.Item key={i}>
                            <img style={{ width: "100%", height: "auto" }}
                                src={item.img}
                                alt="sllide" />
                            <Carousel.Caption>
                                <h2>{item.description}</h2>
                            </Carousel.Caption>
                        </Carousel.Item>

                    )
                })}

            </Carousel>
        </Container>
    )
}

export const Slides = (props) => {
    const items = [{ description: "Beautiful Memories", img: "/images/slides/slide6.jpg" },
    { description: "Capture the moment", img: "/images/portfolio/portraits/portrait16.jpg" },
    { description: "Candids in natural light", img: "/images/slides/slide1.jpg" },
    { description: "Getting ready for wedding", img: "/images/slides/slide2.jpg" },
    { description: "Couple photoshoot", img: "/images/slides/slide3.jpg" },
    { description: "Wedding moments", img: "/images/slides/slide4.jpg" },
    { description: "Portraits", img: "/images/slides/slide5.jpg" },

    ]

    return (



        <CarouselItems items={items} />




    )
}