
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from './views/Header';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import { Home } from './views/Home';
import { Portfolio } from './views/Portfolio';
import { Packages } from './views/Packages';
import { Contact } from './views/Contact';
import { Footer } from './views/Footer';
import { BrowserRouter as Router} from 'react-router-dom';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { ContextProvider } from './store/ContextPorovider';
import Query from './components/Query';





function App() {

  const [bottomReached, setBottomReached] = useState(false)
  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  const handleScroll = () => {

  
    const appElement = document.getElementById("app");
  
    setBottomReached(isBottom(appElement))

  }
  useEffect(() => {
    
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    }

  }, [])
 
  return (
    <div style={{ width: "100%" }} id='app' className='app'>
      <Routes >
      <Route element={<ContextProvider />}>
          <Route path="/" element={<Header />} >
            <Route index element={<Home />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="packages" element={<Packages />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="query" element={<Query />} />
          </Route>
          </Route>
      </Routes>
      {bottomReached === true && <Footer />}

    </div>)





}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
export default App;
