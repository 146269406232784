import { useContext } from "react"
import { Slides } from "../components/CaraouselComponent"
import { PhotoServices } from "./Packages"
import { Zoom } from "react-reveal"
import { MobileContext } from "../store/ContextPorovider"

export const Home = () => {
    const {isMobile} = useContext(MobileContext)
    

    return (
        <div >
            <Zoom>
                
                <h3 style={{ width: "50%", margin: "auto", padding: 20, textAlign:"center", fontFamily: 'Times New Roman, serif' }}>welcome to AK Photography</h3>
                <Slides />
            </Zoom>
            <div style={{width:"40%", margin:"auto", background:"gray", borderRadius:"20px",
             textAlign:"center", color:"white"}}>

            
          

            <p style={{marginTop:"5em", padding: "0.2em", fontFamily: 'Times New Roman, serif', fontSize: isMobile ? "12px": "25px" }}> our services include</p>
            </div>
            <PhotoServices />
           
        </div>

    )
}