import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"

const SocialButton = (props) => {
    const image = props.image;
    const title = props.title
    const link = props.link
    return (
        <div >
            <a href={link} target="_blank" rel="noreferrer">
                <Row>
                    <Col xs={12} >
                        <img src={image} alt={title} height={30} style={{padding:0, margin:0}}/>
                    </Col>
                    <Col xs={12}>{title}</Col>
                </Row>
            </a>
        </div>
    )
}
export default SocialButton;