
import { Outlet } from "react-router-dom"
import React, { useContext, useState, useEffect } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { MobileContext } from "../store/ContextPorovider"


export const Header = () => {
    const links = {
        textDecoration: "none",
        color: "green",
        margin: 10,
        padding: 5,
        transition: '3s color',
        '&:hover': {
            color: "red",
            background: '#40a9ff'
        }
    }
    const [title, setTitle] = useState("Home");
    const [showTitle, setShowTitle] = useState(false);
   
    const {isMobile, setMobile} = useContext(MobileContext)

    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
    
        setMobile(width <= 768)
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
   
    useEffect(() => {
        setMobile(width <= 768)

    }, [width])
    const onSelect = (eventKey, e) => {
  
        switch (eventKey) {
            case "1":
                setTitle("Home")
                break;
            case "2":
                setTitle("Portfolio")
                break;
            case "3":
                setTitle("Services")
                break;
            case "4":
                setTitle("Contact")
                break;
            default:
                setTitle("Home")
                break;

        }
    }
    const onToggle = (expanded) => {
       
        setShowTitle(true);
    }
    return (
        <div style={{ margin: "10px", padding: 5, borderRadius: "20px" }}>

            <Navbar bg="light"
                expand="md"
                variant="light"
                collapseOnSelect
                onSelect={onSelect}
                onToggle={onToggle}
                className="navbar navbar-expand-lg navbar-light bg-light" style={{ zIndex: 95, borderRadius: "20px", }}>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isMobile && title}
                <LinkContainer to="/">
                    <Nav.Link eventKey={"1"}>
                        <img src="/logo.png" height={60} style={{ marginLeft: 5 }} alt="ak photography" />
                    </Nav.Link>
                </LinkContainer>
                <Navbar.Collapse id="basic-navbar-nav">

                    <Nav className="mr-auto" style={{ fontSize: 25 }}>

                        <LinkContainer to="/">
                            <Nav.Link eventKey={"1"} >Home

                            </Nav.Link>
                        </LinkContainer>

                        <LinkContainer to="portfolio">
                            <Nav.Link eventKey={"2"}>Portfolio</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="packages">
                            <Nav.Link eventKey={"3"}>Services</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="contact">
                            <Nav.Link eventKey={"4"}>Contact</Nav.Link>
                        </LinkContainer>

                    </Nav>


                </Navbar.Collapse>


            </Navbar>

            <Outlet />
        </div>


    )





}