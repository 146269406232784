import { useContext, useEffect, useState } from "react";
import React from "react";
import './GalleryGrid.css';
import { Flip } from "react-reveal";
import { MobileContext } from "../store/ContextPorovider";

const PreviewImage = (props) => {
  
    const {isMobile} = useContext(MobileContext);

    const img = props.img;
    const tab = props.tab
    var modal = document.getElementById(tab + "imageModal")
    if (isMobile === true){
        if (modal !== null){
            modal.style.display = "none"
        }
        
    }

    const closePreview = () => {
        modal.style.display = "none"
        console.log("close clicked")

    }
  

   

    return (
     <div className="image-modal" id={tab + "imageModal"} style={{zIndex:99}}>
        
        <div className="modal-content">
            <span className="close" onClick={() => closePreview()}>&times;</span>
            <img src={img} alt="img" style={{ maxWidth: "80%", width: "auto", height: "auto" }} id="prevs"/>
        </div>

    </div>)

    }

const ImageGrid = (props) => {
    const images = props.images
    const title = props.title

    const [selectedImage, setImage] = useState(0);
    const showImage = (imageIndex) => {
        setImage(imageIndex);
        var modal = document.getElementById(title + "imageModal")
        modal.style.display = "block"

    }

    return (<div style={{ display: "flex", flexWrap: "wrap-reverse", padding: "0 4px", backgroundColor: 'gray' }}>

        <PreviewImage img={images[selectedImage].img} tab={title} />
        <div style={{ flex: "10%", padding: "0 4px" }} >
            {images.map((image, i) => {
                return <img src={image.img} alt="portfolio" className="gallery-image"
                    style={{ width: "100%" }}
                    onClick={() => showImage(i)}
                    key={i} />
            }

            )}

        </div>


    </div>)
}

export const Grid = (props) => {
    const images = props.images
    const title = props.title

    return (
        <ImageGrid images={images} style={{ backgroundColor: 'gray' }} title={title} />

    )
}