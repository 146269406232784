import React, {useState} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

// const SizeType = Parameters<typeof Form>[0]['size'];
const Query = ()=>{
    const [state, handleSubmit] = useForm("moqoraka");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    // const [componentSize, setComponentSize] = useState<'large' | 'default'>('large');

    // const onFormLayoutChange = ({ size }: { size: SizeType }) => {
    //   setComponentSize(size);
    // };


    return <div>
         <Form>

       
        <FloatingLabel  controlId="floatingInput"
        label="Email address"
        className="mb-3"
      >
        <Form.Control type="email" placeholder="name@example.com" />
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Have you had a Golden retriever before?">
        <Form.Control type="text" placeholder="Password" />
      </FloatingLabel>
      </Form>
    </div>
}
export default Query;